import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { Config } from "@/shared/config";
import _ from "lodash";
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = Config.apiUrl;
  },

  setHeader() {
    let token = localStorage.getItem("userToken");
    axios.defaults.headers.common.Authorization = token;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    let loggedInUserId = localStorage.getItem("userLoggedInId");
    axios.defaults.headers.auid = window.btoa(loggedInUserId);
  },

  async post(resource, params) {
    ApiService.setHeader();
    let data = await axios.post(Config.apiUrl + `${resource}`, params, {
      headers: {
        "X-localization": "en",
      },
    });

    if (
      _.get(data, "data.code", "") == "217" ||
      _.get(data, "data.code", "") == "218" ||
      _.get(data, "data.code", "") == "222" &&
      window.location.pathname != "/login"
    ) {
      localStorage.clear();
      this.clearSession(data.data.message);
    }
    return data;
  },

  clearSession() {
    localStorage.clear();
    localStorage.removeItem("vuex");
    localStorage.removeItem("userToken");
    window.location.href = "/";
  },

  async get(resource, params) {

    ApiService.setHeader();
    let data = await axios.get(
      Config.apiUrl + `${resource}`,
      { params },
      {
        headers: {
          "X-localization": "en",
        },
      }
    );
    if (
      _.get(data, "data.code", "") == "217" ||
      _.get(data, "data.code", "") == "218" ||
      _.get(data, "data.code", "") == "222" &&
      window.location.pathname != "/login"
    ) {
      localStorage.clear();
      this.clearSession(data.data.message);
    }
    return data;
  },

  async put(resource, params) {

    ApiService.setHeader();
    let data = await axios.put(Config.apiUrl + `${resource}`, params, {
      headers: {
        "X-localization": "en",
      },
    });
    if (
      _.get(data, "data.code", "") == "217" ||
      _.get(data, "data.code", "") == "218" ||
      _.get(data, "data.code", "") == "222" &&
      window.location.pathname != "/login"
    ) {
      localStorage.clear();
      this.clearSession(data.data.message);
    }
    return data;
  },

  async delete(resource, params) {
    ApiService.setHeader();
    let data = await axios.delete(
      Config.apiUrl + `${resource}`,
      { params },
      {
        headers: {
          "X-localization": "en",
        },
      }
    );
    if (
      _.get(data, "data.code", "") == "217" ||
      _.get(data, "data.code", "") == "218" ||
      _.get(data, "data.code", "") == "222" &&
      window.location.pathname != "/login"
    ) {

     

      localStorage.clear();
      this.clearSession(data.data.message);
    }
    return data;
  },
};

export default ApiService;
