<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
  font-family: "Roboto", sans-serif;
  position: relative;
}

#app {
  font-family: "Roboto", sans-serif;
}
.c-app {
  font-size: 15px !important;
  font-family: 'Roboto', sans-serif;
}
.form-control {
    font-family: "Roboto", sans-serif !important;
}

.toast-success {
  background-color: #51a351 !important;
}

.toast-error {
  background-color: #bd362f !important;
}

.toast-icon {
  top: 11px !important;
}
.c-main {
  background-color: #f6f6f6 !important;
}
.btn {
  min-width: 80px !important;
}
.btn-primary{
 min-width: 80px !important;
}
</style>
