export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGGEDIN_USER_DETAILS = "getLoggedInUserDetails";
export const REQUEST_RESET_PASSWORD = "requestResetPassword";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_PASSWORD = "updatePassword";

export const GET_G_CODE_LIST = "getGCodeList";
export const GET_ERROR_CODE_LIST = "getErrorCodeList";
export const GET_WARNING_CODE_LIST = "getWarningCodeList";
export const STORE_CODE = "storeCode";
export const GET_CODE_DETAILS = "getCodeDetails";
export const CODE_DELETE = "codeDelete";
export const CODE_UPDATE = "codeUpdate";
export const SETTINGS_CONTROLLER_LIST = "settingsControllerList";
export const SETTINGS_MTB_LIST = "settingsMtbList";
export const SETTINGS_ADD = "settingsAdd";
export const SETTINGS_UPDATE = "settingsUpdate";
export const SERVICE_MAIL_ADD = "serviceMailAdd";
export const SERVICE_MAIL_LIST = "serviceMailList";
export const SETTINGS_DELETE = "settingsDelete";

export const NOTIFICATION_ADD = "notificationAdd";
export const NOTIFICATION_LIST = "notificationList";
export const NOTIFICATION_DETAILS = "notificationDetails";

export const USERS_LIST = "usersList";
export const USERS_DETAIL = "usersDetail";
export const USERS_DELETE = "usersDelete";
export const USERS_ROLE_UPDATE = "usersRoleUpdate";
export const USERS_ROLE_LIST = "usersRoleList";
export const SERVICE_REQUEST_LIST = "serviceRequestList";
export const SERVICE_ASSIGN_ENGINEER = "serviceAssignEngineer"
export const SERVICE_DROPDOWN_ENGINEER = "serviceDropdownEngineer"
export const WARRANTY_REQUEST_LIST = "warrantyRequestList";
export const WARRANTY_DETAILS = "warrantyDetails";
export const SERVICE_DETAILS = "serviceDetails";

export const ADMIN_PROFILE_LIST = "adminProfile";
export const ADMIN_PROFILE_EDIT = "adminProfileEdit";

export const SETTINGS_SORT_SAVE = "settingsSortSave";
export const SETTINGS_SORT_LIST = "settingsSortList";
export const DELETE_NOTIFICATIONS = "deleteNotifications";
export const SETTINGS_REGION_SORT_LIST = "settingsRegionSortList";
export const SETTINGS_REGION_MASTER_SAVE = "settingRegionMasterSave";
export const SETTINGS_REGION_WEEK_OFF_SORT_LIST = "settingsRegionWeekOffSortList";
export const SETTINGS_REGION_WEEK_OFF_SAVE = "settingsRegionWeekOffSave";

export const MANAGER_LIST = "managerList";
export const MANAGER_ADD = "managerAdd";
export const MANAGER_DELETE = "managerDelete";
export const MANAGER_EDIT = "managerEdit";
export const MANAGER_DETAILS = "managerDetails";
export const MANAGER_REGION_LIST = "managerRegionList";
export const MANAGER_PROFILE_UPDATE = "managerProfileUpdate";
export const MANAGER_ROLE_LIST = "managerRoleList";

export const SHIPMENT_DATA_LIST = "shipmentdatalist";
export const UPDATE_SHIPMENT_DETAILS = "updateShipmentDetails";
export const UPLOAD_SHIPMENT_FILE = "uploadShipmentFIle";

export const WARRANTY_LIST_NEW = "warrantyListNew";
export const WARRANTY_DETAILS_NEW = "warrantyDetailsNew";
export const WARRANTY_CSV_NEW = "warrantyCsvNew";
export const WARRANTY_PDF_NEW = "warrantyPdfNew";
export const MACHINE_REQUEST_CSV_NEW = "warrantyRequestCsvNew";

export const GET_REGION_DATA = "getRegionData";
export const STORE_REGION_DATA = "storeRegionData";
export const REGIONS_FOR_DROPDOWN = "regionsForDropdown";

export const CUSTOMER_LIST = "getCustomerList";
export const CUSTOMER_DETAILS = "getCustomerDetails";

export const MACHINE_REGISTER_LIST = "machineRegisterList";
export const MACHINE_REGISTER_DETAILS = "machineRegisterDetails";
export const CUSTOMER_MARK_AS_PROCESSED = "markAsProcessedCustomers";

export const DELETE_USER_BY_ID = "deleteUserById";
export const SERVICE_ZONAL_REGIONS = "serviceZonalRegions";
export const MEDIA_IMAGE = "servicemanagerData"; 

// Constants for product actions
export const PRODUCT_LIST = "productList";
export const PRODUCT_DETAILS = "productDetails";
export const PRODUCT_CREATE = "productCreate";
export const PRODUCT_UPDATE = "productUpdate";
export const PRODUCT_DELETE = "productDelete";

export const PRODUCT_CATEGORY_LIST = "productCategoryList";
export const PRODUCT_CATEGORY_DETAILS = "productCategoryDetails";
export const PRODUCT_CATEGORY_CREATE = "productCategoryCreate";
export const PRODUCT_CATEGORY_UPDATE = "productCategoryUpdate";
export const PRODUCT_CATEGORY_DELETE = "productCategoryDelete";
export const PRODUCT_CATEGORY_DROPDOWN = "productCategoryDropdown";

// Constants for promotion actions
export const PROMOTION_LIST = "promotionList";
export const PROMOTION_DETAILS = "promotionDetails";
export const PROMOTION_CREATE = "promotionCreate";
export const PROMOTION_UPDATE = "promotionUpdate";
export const PROMOTION_DELETE = "promotionDelete";


export const LEAVE_LIST = "leaveList";
export const LEAVE_MONTH_LIST = "leaveMonthList";
export const LEAVE_CREATE = "leaveCreate";