import ApiService from "@/services/apiservice";
import _ from "lodash";
import {
  GET_G_CODE_LIST,
  GET_ERROR_CODE_LIST,
  GET_WARNING_CODE_LIST,
  STORE_CODE,
  GET_CODE_DETAILS,
  CODE_DELETE,
  CODE_UPDATE,
  SETTINGS_CONTROLLER_LIST,
  SETTINGS_MTB_LIST,
  SETTINGS_ADD,
  SETTINGS_UPDATE,
  SERVICE_MAIL_ADD,
  SERVICE_MAIL_LIST,
  SETTINGS_DELETE,
  NOTIFICATION_ADD,
  NOTIFICATION_LIST,
  NOTIFICATION_DETAILS,
  USERS_LIST,
  USERS_DETAIL,
  USERS_DELETE,
  USERS_ROLE_UPDATE,
  USERS_ROLE_LIST,
  SERVICE_REQUEST_LIST,
  SERVICE_ASSIGN_ENGINEER,
  SERVICE_DROPDOWN_ENGINEER,
  WARRANTY_REQUEST_LIST,
  WARRANTY_DETAILS,
  SERVICE_DETAILS,
  ADMIN_PROFILE_LIST,
  ADMIN_PROFILE_EDIT,
  SETTINGS_SORT_LIST,
  SETTINGS_SORT_SAVE,
  DELETE_NOTIFICATIONS,
  SETTINGS_REGION_SORT_LIST,
  SETTINGS_REGION_MASTER_SAVE,
  SETTINGS_REGION_WEEK_OFF_SORT_LIST,
  SETTINGS_REGION_WEEK_OFF_SAVE,
  MANAGER_LIST,
  MANAGER_ADD,
  MANAGER_DELETE,
  MANAGER_EDIT,
  MANAGER_DETAILS,
  MANAGER_REGION_LIST,
  MANAGER_ROLE_LIST,
  MANAGER_PROFILE_UPDATE,
  SHIPMENT_DATA_LIST,
  UPDATE_SHIPMENT_DETAILS,
  UPLOAD_SHIPMENT_FILE,
  WARRANTY_LIST_NEW,
  WARRANTY_DETAILS_NEW,
  WARRANTY_CSV_NEW,
  WARRANTY_PDF_NEW,
  MACHINE_REQUEST_CSV_NEW,
  GET_REGION_DATA,
  STORE_REGION_DATA,
  REGIONS_FOR_DROPDOWN,
  CUSTOMER_LIST,
  CUSTOMER_DETAILS,
  MACHINE_REGISTER_LIST,
  MACHINE_REGISTER_DETAILS,
  CUSTOMER_MARK_AS_PROCESSED,
  DELETE_USER_BY_ID,
  SERVICE_ZONAL_REGIONS,
  MEDIA_IMAGE,
  PRODUCT_LIST,
  PRODUCT_DETAILS,
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_CATEGORY_LIST,
  PRODUCT_CATEGORY_DETAILS,
  PRODUCT_CATEGORY_CREATE,
  PRODUCT_CATEGORY_UPDATE,
  PRODUCT_CATEGORY_DELETE,
  PRODUCT_CATEGORY_DROPDOWN,
  PROMOTION_LIST,
  PROMOTION_DETAILS,
  PROMOTION_CREATE,
  PROMOTION_UPDATE,
  PROMOTION_DELETE,
  LEAVE_LIST,
  LEAVE_MONTH_LIST,
  LEAVE_CREATE
} from "./actions.type";
import { } from "./mutations.type";

const initialState = {
  errors: null,
};
const getters = {};

export const state = { ...initialState };

export const actions = {
  async [STORE_CODE](context, payload) {
    try {
      return await ApiService.post("/admin/code/store", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [GET_G_CODE_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/code/gcode/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [GET_ERROR_CODE_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/code/ecode/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [GET_WARNING_CODE_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/code/wcode/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [GET_CODE_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/code/detail", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [CODE_UPDATE](context, payload) {
    try {
      return await ApiService.put("/admin/code/update", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [CODE_DELETE](context, payload) {
    try {
      return await ApiService.delete("/admin/code/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [SETTINGS_CONTROLLER_LIST](context, payload) {
    try {
      let { data } = await ApiService.post(
        "/admin/device/controller/list",
        payload
      );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SETTINGS_MTB_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/device/mtb/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SETTINGS_ADD](context, payload) {
    try {
      return await ApiService.post("/admin/device/store", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [SETTINGS_UPDATE](context, payload) {
    try {
      return await ApiService.put("/admin/device/update", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [SERVICE_MAIL_ADD](context, payload) {
    try {
      return await ApiService.put("/admin/mail/update", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [SERVICE_MAIL_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/device/mail/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SETTINGS_DELETE](context, payload) {
    try {
      return await ApiService.delete("/admin/device/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [NOTIFICATION_LIST](context, payload) {
    try {
      let { data } = await ApiService.post(
        "/admin/notifications/list",
        payload
      );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [NOTIFICATION_ADD](context, payload) {
    try {
      return await ApiService.post("/admin/notifications/store", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [NOTIFICATION_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get(
        "/admin/notifications/detail",
        payload
      );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [USERS_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/user/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [USERS_DELETE](context, payload) {
    try {
      return await ApiService.put("/admin/user/status/update", payload);
    } catch (error) {
      return error.response;
    }
  },


  async [USERS_ROLE_UPDATE](context, payload) {
    try {
      return await ApiService.put("/admin/user/roleupdate", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [USERS_ROLE_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/role/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [USERS_DETAIL](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/user/detail", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SERVICE_REQUEST_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/service/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [SERVICE_DROPDOWN_ENGINEER](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/service/scheduler/fetch/engineer", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  
  async [SERVICE_ASSIGN_ENGINEER](context, payload) {
    try {
      return await ApiService.post("/admin/service/scheduler/upsert", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [WARRANTY_REQUEST_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/warranty/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SERVICE_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/service/detail", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [WARRANTY_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/warranty/detail", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [ADMIN_PROFILE_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/user/profile/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [ADMIN_PROFILE_EDIT](context, payload) {
    try {
      return await ApiService.put("/admin/user/profile/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [SETTINGS_SORT_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/device/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [SETTINGS_REGION_SORT_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/region/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [SETTINGS_REGION_WEEK_OFF_SORT_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/regions/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [SETTINGS_REGION_WEEK_OFF_SAVE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/regions/bulk/weekoff", payload);
       
    } catch (error) {
      return error.response.data;
    }
  },
  async [SETTINGS_SORT_SAVE](context, payload) {
    try {
      return await ApiService.post("/admin/device/store", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [SETTINGS_REGION_MASTER_SAVE](context, payload) {
    try {
      return await ApiService.post("/admin/region/store", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [DELETE_NOTIFICATIONS](context, payload) {
    try {
      return await ApiService.delete("/admin/notifications/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [MANAGER_LIST](context, payload) {
    try {
      let data = await ApiService.post("/manager/list", payload);
      return _.get(data, "data", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MANAGER_ADD](context, payload) {
    try {
      return await ApiService.post("/manager/create", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [MANAGER_DELETE](context, payload) {
    try {
      return await ApiService.delete("/manager/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [MANAGER_EDIT](context, payload) {
    try {
      return await ApiService.post("/manager/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [MANAGER_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/manager/read", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MANAGER_REGION_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/region/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MANAGER_ROLE_LIST](context, payload) {
    try {
      let { data } = await ApiService.get("/manager/dropdown/role/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MANAGER_PROFILE_UPDATE](context, payload) {
    try {
      return await ApiService.post("/user/manager/profile/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [SHIPMENT_DATA_LIST](context, payload) {
    try {
      let data = await ApiService.post("/admin/shipment/list", payload);
      return _.get(data, "data", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [UPDATE_SHIPMENT_DETAILS](context, payload) {
    try {
      return await ApiService.put("/admin/shipment/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [UPLOAD_SHIPMENT_FILE](context, payload) {
    try {
      return await ApiService.post("/admin/shipment/import", payload);
    } catch (error) {
      return error.response.data;
    }
  },
  async [WARRANTY_LIST_NEW](context, payload) {
    try {
      let { data } = await ApiService.post(
        "/admin/warranty/request/list",
        payload
      );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [WARRANTY_DETAILS_NEW](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/warranty/request/detail", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [WARRANTY_CSV_NEW](context, payload) {
    try {
      return await ApiService.post("/admin/warranty/request/export", payload);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MACHINE_REQUEST_CSV_NEW](context, payload) {
    try {
      return await ApiService.post("/admin/machine/export", payload);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [STORE_REGION_DATA](context, payload) {
    try {
      return await ApiService.put(
        "/admin/master/country/state/update",
        payload
      );
    } catch (error) {
      return error.response.data;
    }
  },

  async [GET_REGION_DATA](context, payload) {
    try {
      let { data } = await ApiService.post(
        "/admin/master/country/state/list",
        payload
      );
      return _.get(data, "model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [WARRANTY_PDF_NEW](context, payload) {
    try {
      return await ApiService.get(
        "/business/warranty/request/download",
        payload
      );
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [CUSTOMER_LIST](context, payload) {
    try {
      let data = await ApiService.post("/business/customer/list", payload);
      return _.get(data, "data", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [CUSTOMER_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/business/customer/detail", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [CUSTOMER_MARK_AS_PROCESSED](context, payload) {
    try {
      return await ApiService.put("/business/customer/update", payload);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [REGIONS_FOR_DROPDOWN](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/region/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MACHINE_REGISTER_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/admin/machine/details", payload);
      return data;
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [MACHINE_REGISTER_LIST](context, payload) {
    try {
      let { data } = await ApiService.post("/admin/machine/list", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [DELETE_USER_BY_ID](context, payload) {
    try {
      return await ApiService.delete("/admin/user/account/delete", payload);
    } catch (error) {
      return error.response;
    }
  },
  async [SERVICE_ZONAL_REGIONS](context, payload) {
    try {
      let data = await ApiService.post("/admin/master/country/state/region/list", payload);
      return _.get(data, "data", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [MEDIA_IMAGE](context, payload) {
    try {
      let data = await ApiService.get("/admin/download/file", payload);
      return _.get(data, "data", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [PRODUCT_LIST](context,payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/products/list",payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PRODUCT_CREATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/products/create", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PRODUCT_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/v2/admin/products/detail", payload );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PRODUCT_UPDATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/products/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PRODUCT_DELETE](context, payload) {
    try {
      return await ApiService.delete("/v2/admin/products/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [PRODUCT_CATEGORY_LIST](context,payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/categories/list",payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PRODUCT_CATEGORY_CREATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/categories/create", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PRODUCT_CATEGORY_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.get("/v2/admin/categories/detail", payload );
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PRODUCT_CATEGORY_UPDATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/categories/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PRODUCT_CATEGORY_DELETE](context, payload) {
    try {
      return await ApiService.delete("/v2/admin/categories/delete", payload);
    } catch (error) {
      return error.response;
    }
  },

  async [PRODUCT_CATEGORY_DROPDOWN]() {
    try {
      let { data } = await ApiService.get("/v2/admin/categories/dropdown");
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PROMOTION_LIST](context,payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/promotions/list",payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PROMOTION_CREATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/promotions/create", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PROMOTION_DETAILS](context, payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/promotions/detail", payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },

  async [PROMOTION_UPDATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/promotions/update", payload);
    } catch (error) {
      return error.response.data;
    }
  },

  async [PROMOTION_DELETE](context, payload) {
    try {
      return await ApiService.delete("/v2/admin/promotions/delete", payload );
    } catch (error) {
      return error.response;
    }
  },
  async [LEAVE_LIST](context,payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/leaves/list",payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [LEAVE_MONTH_LIST](context,payload) {
    try {
      let { data } = await ApiService.post("/v2/admin/leaves/monthly",payload);
      return _.get(data, "data.model", []);
    } catch (error) {
      return { success: false, message: error.toString() };
    }
  },
  async [LEAVE_CREATE](context, payload) {
    try {
      return await ApiService.post("/v2/admin/leaves/upsert", payload);
    } catch (error) {
      return error.response.data;
    }
  },
};

export const mutations = {};

export default {
  actions,
  mutations,
  getters,
};
