import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  en: {
    "app.title": "Title",
    "app.edit.icon": "Edit",
    "app.delete.icon": "Delete",
    "app.login.forgotpassword": "Forgot your password",
    /**en strings for accounts list starts here */
    "app.account.list.type": "type",
    "app.account.iist.number.of.referals": "referal",
    "app.account.list.payment": "payment",
    "app.generic.deleteconfirmation.ok": "yes",
    "app.generic.deleteconfirmation.cancel": "no",
    "app.acoount.deleteconfirmation": "Are You delete the data ?",
    "app.table.perpage": "per page",
    "app.generic.norecordsfound": "No records found.",
    "app.account.list.title": "Accounts list",
    "app.accounts.create.button": "create",
    "app.acounts.name.label": "account name",
    "app.acounts.mail.label": "mail",
    "app.acounts.apply.code.label": "account code",
    "app.acounts.status.label": "status",
    "app.acounts.acion.label": "action",
    "app.acounts.type.label": "type",
    "app.accounts.discount.label": "label",
    /**en strings for accounts list ends here */

    /**en strings for admin list starts here */
    "app.account.admin.list": "Admin list",
    "äpp.account.admin.delete.message": "Delete confirmation",
    "app.admin.list.name": "name",
    "app.admin.list.mail": "mail",
    "app.admin.list.role": "role",
    "app.admin.create.add.admin": "Add admin",
    "app.admin.create.add.admin.name": "name",
    "app.admin.create.add.admin.mail": "mail",
    "app.admin.create.add.admin.role": "role",
    "app.admin.create.add.admin.cancel": "cancel",
    "app.admin.create.add.admin.save": "save",
    "app.admin.edit.label": "edit",
    "app.admin.create.page.label": "create new",
    /**en strings for admin list ends here */
    "app.login.forgotpassword.reset": "FORGOT PASSWORD",
    "app.login.forgot.password.placeholder": "Please enter your Email ID",
    "app.forgotpassword.btn.send": "Submit",
    "app.return.login": "Return to Login",
    MAXLENGTH: "Min length",
    MINLENGTH: "Max length",
    REQUIREDFIELD: "Please enter the mandatory field.",
    NODATAFOUND: "No data found.",
    "app.generic.pleaseprovide": "Please enter the mandatory field.",
    "app.forgotpassword.link.sent":
      "We have sent you an email to reissue your password.",
    "app.reset.pass.title": "Reset Password",
    "app.profile.title": "Profile Details",
    "app.login.password": "Password",
    "app.same.confirm.password.profile": "Confirm Password",
    "app.character.save.button": "Save",
    "app.password.mustbesame": "Password must be same.",
    "app.validationerror.email": "Mail address format is wrong.",
    "app.validationerror.maxlength": "Maxlength",
    "app.validationerror.pincode": "Pin code is wrong.",
    "app.validationerror.phoneNumber": "Phone number is wrong.",
    "app.validationerror.numbervalidation": "Number format is wrong.",
    "app.validationerror.password":
      "Must be a string of at least 8 characters, including at least one uppercase,symbol and number.",
    "app.validationerror.notSameEmail": "Confirm email is not same email.",
    "app.accountRegistation.cancel": "Cancel",
    "app.validationerror.maxlengthFirstString":
      "Entered characters are exceeding",
    "app.validationerror.maxlengthScrondString": "characters.",
    "app.validationerror.senitize": "Special characters are not allowed",
    "app.validationerror.allowAddress": "Special characters are not allowed",
    "app.validationerror.senitizeNcModel": "Special characters are not allowed",
    "app.validationerror.senitizeMtbName": "Special characters are not allowed",
    "app.validationerror.sanitizedUrl": "Special characters are not allowed",
    "app.validationerror.sanitizedDescription": "Special characters are not allowed",
  },
  ja: {
    "app.title": "Title",
    "app.edit.icon": "編集",
    "app.delete.icon": "削除",
    "app.login.subtitle": "管理システム",
    "app.generic.loginID": "メールアドレス",
    "app.login.password": "パスワード",
    "app.login.keeploggedin": "ログイン情報を記憶する",
    "app.lable.login": "ログイン",

    "app.login.forgotpassword": "PWをわすれた",
    "app.login.forgotpasswordhear": "こちらから",
    "app.login.forgotpasswordcontact": "ご連絡ください。",
    "app.header.profile": "プロフィール設定",
    "app.resetpassword.text": "ユーザーID",
    "app.login.mailid": "Email ID",
    "app.reset.password": "Reset password",
    "app.login.mailTo.subject": "管理システムのログイン情報",
    "app.login.mailTo.body":
      "管理システムご担当者さま %0A %0A ログイン情報を忘れたため、管理システムへログインできません。 %0A パスワード再発行のご対応お願いします。",
    "app.generic.pleaseprovide": "必須項目を入力して下さい。",
    /**jp strings for validation started here */
    "app.validationerror.email": "メールアドレスが不正です。",
    "app.validationerror.kana": "登録しているデータが正しくありません。",
    "app.validationerror.maxlength": "最大文字列長",
    "app.validationerror.pincode": "郵便番号が不正です。",
    "app.validationerror.phoneNumber": "電話番号が不正です。",
    "app.validationerror.numbervalidation":
      "登録しているデータが正しくありません。",
    "app.validationerror.password": "パスワードフォーマットが不正です。",
    "app.validationerror.notSameEmail":
      "同様のメールアドレスを使用することはできません。",
    "app.login.forgotpassword.reset": "PW忘れ",
    "app.login.pleaseEnterRegisteredEmail": "登録しているメールアドレスを入力",
    "app.forgotpassword.email": "メールアドレス",
    "app.forgotpassword.procedure":
      "ご登録して頂いたメールアドレスにパスワード再発行手続きのメールをお送りします。",
    "app.forgotpassword.procedure1":
      "メールアドレスをお忘れの場合は、園の管理者へ直接お問い合わせください。",
    "app.forgotpassword.btn.send": "PW再設定",
    "app.forgotpassword.link.sent":
      "パスワード再発行手続きのメールを送信しました。",
    "app.forgotpassword.reset.procedure":
      "受信メールをご確認の上、パスワード再発行手続きを行ってください。",
    "app.forgotpassword.reset.procedure2":
      "※メールが迷惑フォルダに入っていることがございます。",
    "app.resetpassword.newpassword": "新しいパスワードを",
    "app.resetpassword.newpassword2": "入力してください。",
    "app.resetpassword.email": "メールアドレス",
    "app.resetpassword.confirmpassword": "新しいパスワード ( 確認用 )",
    "app.resetpassword.password1": "新しいパスワード",
    "app.resetpassword.btn.changeandlogin": "変更してログイン",
    "app.password.mustbesame":
      "パスワードと確認パスワードが同じである必要です。",
    "app.email.mustbesame": "メールは同じである必要があります。",
    "app.resetpassord.text": "パスワードの再発行",
    "app.validationerror.networkerror":
      "エラーが発生しました。時間をおいてもう一度お試しください。",
    "app.accountRegistation.tab.name": "アカウント名",
    "app.accountRegistation.tab.email": "メールアドレス",
    "app.accountRegistation.tab.password": "パスワード",
    "app.accountRegistation.tab.Address": "住所",
    "app.return.login": "ログインページへ戻る",
    "app.accountRegistation.password": "パスワード",
    "app.accountRegistation.mobile": "携帯番号",
    "app.accountRegistation.title": "アカウント追加",
    "app.accountRegistation.name": "氏名",
    "app.accountRegistation.email": "メールアドレス",
    "app.accountRegistation.confirm.email": "確認メール",
    "app.accountRegistation.postalcode": "郵便番号",
    "app.accountRegistation.prefecture": "都道府県",
    "app.accountRegistation.municipality": "市区町村",
    "app.accountRegistation.address": "住所",
    "app.accountRegistation.type": "種別",
    "app.accountRegistation.affiliate": "紹介者コード",
    "app.accountRegistation.referal": "紹介者",
    "app.accountRegistation.notes": "メモ",
    "app.accountRegistation.status": "ステータス",
    "app.accountRegistation.apply_status": "ステータス",
    "app.accountRegistation.cancel": "キャンセル",
    "app.accountRegistation.save": "登録",
    "app.accountRegistation.payment": "種別",
    "app.accountRegistation.monthly": "月極",
    "app.accountRegistation.yearly": "年間",
    "app.accountRegistation.onetime": "一括",
    "app.accountRegistation.city": "シティ",
    "app.accountRegistation.role": "役柄",

    /**jp strings for validation end here */
    /**jp strings for accounts list starts here */
    "app.account.list.type": "種別",
    "app.account.iist.number.of.referals": "紹介回数",
    "app.account.list.payment": "支払い",
    "app.generic.deleteconfirmation.ok": "削除",
    "app.generic.deleteconfirmation.cancel": "キャンセル",
    "app.acoount.deleteconfirmation": "のデータを削除しますか？",
    "app.table.perpage": "表示件数",
    "app.generic.norecordsfound": "表示する情報がありません。",
    "app.account.list.title": "アカウント一覧",
    "app.accounts.create.button": "新規作成",
    "app.acounts.name.label": "アカウント名",
    "app.acounts.mail.label": "メール",
    "app.acounts.apply.code.label": "紹介者コード",
    "app.acounts.status.label": "ステータス",
    "app.acounts.acion.label": "アクション",
    "app.acounts.type.label": "請求",
    "app.accounts.discount.label": "利用回数",
    /**jp strings for accounts list ends here */

    /**jp strings for admin list starts here */
    "app.account.admin.list": "管理者一覧",
    "äpp.account.admin.delete.message": "のデータを削除しますか？",
    "app.admin.list.name": "氏名の入力欄",
    "app.admin.list.mail": "メールアドレスの入力欄",
    "app.admin.list.role": "ﾛッﾙの入力欄",
    "app.admin.create.add.admin": "管理者追加",
    "app.admin.create.add.admin.name": "氏名",
    "app.admin.create.add.admin.mail": "メールアドレス",
    "app.admin.create.add.admin.role": "ﾛッﾙ",
    "app.admin.create.add.admin.cancel": "キャンセル",
    "app.admin.create.add.admin.save": "登録",
    "app.admin.edit.label": "編集",
    "app.generic.pleaseselect": "都道府県選択",
    MAXLENGTH: "最大値",
    MINLENGTH: "最低値",
    REQUIREDFIELD: "必須項目を入力してください。 ",
    NODATAFOUND: "表示する情報がありません。",
    "app.admin.create.page.label": "管理者追加",
    "app.admin.delet.poup.delete": "削除",
    "app.adfmin.delete.popup.cancel": "キャンセル",
    "app.character.title.label": "キャラ - の編集",
    "app.character.save.button": "保存",
    "app.character.title.label.new": "キャラ - 登録の編集",
    "app.animal.title.new": "動物 - 登録の編集",
    "app.rail.new.title": "十大主星 - 登録の編集",
    "app.admin.relationship.title.new": "相性(基本関係) - 登録の編集",
    "app.aisho.friendship.new.title": "相性(友達) - 登録の編集",
    "app.admin.aisho.romance.new.title": "相性(恋愛) - 登録の編集",
    "app.admin.work.new.title": "相性(仕事) - 登録の編集",
    "app.admin.marriage.new.title": "相性(結婚) - 登録の編集",
    "app.generic.notauthorised": "このページに権限がありません",
    "app.pagenotfound.takemehome": "お戻りください",
    "app.validationerror.maxlengthFirstString": "文字数が",
    "app.validationerror.maxlengthScrondString": "最大値を超えています。",
    "app.animal.title.label": "動物 - の編集",
    "app.rail.edit.label": "十大主星 - の編集",
    "app.aishio.basic.edit": "相性(基本関係) - の編集",
    "app.aishio.friend.edit": "相性(友達) - の編集",
    "app.aishio.love.edit": "相性(恋愛) - の編集",
    "app.aishio.marriage.edit": "相性(結婚) - の編集",
    "app.aishio.work.edit": "相性(仕事) - の編集",
    "app.account.header.of.details": "アカウント情報",
    "app.account.street.label": "市区町村",
    "app.account.details.table.header.one": "回数",
    "app.account.details.table.header.two": "請求日",
    "app.account.details.table.header.three": "金額",
    "app.account.details.table.header.four": "ステータス",
    "app.account.details.table.header.five": "請求",
    "app.details.account.button": "語求",
    "app.account.list.viewcount": "利用回数",
    "app.generic.search": "検索",
    "app.account.register.complete": "アカウント登録完了",
    "app.account.register.complete.content": " 登録ありがとうございました。",
    "app.account.register.complete.content2":
      "PWは管理者が承認後に添付いたします。",
    "app.account.register.title": "アカウント登録",
    "app.account.register.close": " 閉じる",
    "app.installment.payment.type": "分割",
    "app.usage.payment": "使用",
    "app.account.referal.code.no": "紹介者コード",
    "app.between.range": "1から24の範囲内の番号を入力してください。",
    "app.login.user.placeholder": "ユーザ名",
    "app.lgin.forgot.title": "PWを忘れた",
    "app.login.forgot.password.placeholder": "登録してあるメールを入力",
    "app.header.title.of.dropdown": "設定",
    "app.header.title.of.profile.redirect": "プロファイル",
    "app.header.title.of.logout.redirect": "ログアウト",
    "app.same.confirm.password.profile": "パスワード確認",
    "app.reset.pass.title": "新規PWを登録してください",
    "app.new.account.add.button.title": "追加",
    "app.status.of.account.new": "ステータス",
    "app.status.of.active.title": "有効",
    "app.status.of.in.active.title": "無効",
    "app.affiliate.radio.input": "未払い",
    "app.affiliate.second.option": "未入金",
    "app.status.of.detail.active": "非表示",
    "app.status.of.detail.inactive": "表示",
    "app.payment.date.of.detail": "支払い日",
    "app.payment.of.detail.label": "使用回数",
    "app.edit.update.button": "変更",
    "app.delete.icon.action": "アクション",
    "app.account.list.naming.title": "名前",
    "app.accountlist.payment.type.list.string": "支払い種別",
    "app.delete.scuccess.message.string": "のデータを削除しました。",
    "app.min.validation.string.one": "最低値文字数が",
    "app.min.validation.string.two": "である必要です。",
    "app.emi.counts": "EMI回数",
    "app.validationerror.senitize": "特殊文字やタグは使用できません。",
    "app.validationerror.allowAddress": "特殊文字やタグは使用できません。",
    "app.validationerror.senitizeNcModel": "特殊文字やタグは使用できません。",
    "app.validationerror.senitizeMtbName": "特殊文字やタグは使用できません。",
    "app.validationerror.sanitizedUrl":"特殊文字やタグは使用できません。",
    "app.validationerror.sanitizedDescription":"特殊文字やタグは使用できません。",
    /**jp strings for admin list ends here */
  },
};

const i18n = new VueI18n({ locale: "en", fallbackLocale: "ja", messages });

export default i18n;
