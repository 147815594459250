export const SET_AUTH = "setUser";
export const CLEAR_VUEX = "clearVuex";
export const SET_LOGGEDIN_USER_DETAILS = "setLoggedInUserDetails";
export const SET_ERROR = "setError";
export const SET_ACCOUNT_LIST = "setAccountList";
export const SET_ACCOUNT_VIEW = "setAccountView";
export const SET_BUSINESS_USER_LIST = "setBusinessList";
export const SET_LOGGEDIN_USER_ROLE_PERMISSIONS =
  "setLoggedInUserRolePermissions";
export const SET_PREFECTURE_LIST = "setPrefectureList";
export const SET_REGION = "setRegion";
