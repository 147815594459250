import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import master from "./master.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  userListPayLoad:null,
  serviceListPayLoad:null,
  machineListPayLoad:null,
  managerListPayLoad:null,
  customerListPayLoad:null,
  warrantyListPayLoad:null,
  selectedProduct:null,
  leaveListPayLoad:null,
  selectedEngineer:null
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  clearPayloads(state) {
    state.userListPayLoad = null;
    state.serviceListPayLoad = null;
    state.machineListPayLoad = null;
    state.managerListPayLoad = null;
    state.customerListPayLoad = null;
    state.warrantyListPayLoad = null;
    state.selectedProduct = null;
    state.leaveListPayLoad = null;
  },
};
export default new Vuex.Store({
  state,
  mutations,
  modules: {
    auth,
    master,
  },
  plugins: [createPersistedState()],
});
