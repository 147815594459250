import ApiService from "@/services/apiservice";
import _ from "lodash";
import {
  LOGIN,
  REQUEST_RESET_PASSWORD,
  UPDATE_PASSWORD,
  LOGOUT,
} from "./actions.type";
import {
  SET_AUTH,
  SET_LOGGEDIN_USER_DETAILS,
  CLEAR_VUEX,
  SET_REGION,
} from "./mutations.type";

const getDefaultState = () => {
  return {
    errors: null,
    user: {},
    access_token: "",
    logged_in_user_details: {},
    logged_in_user_role_permissions: {},
    roleID: null,
    regionName: null,
    region_details: {
      region_id: "",
      region_name: "",
    },
  };
};
const state = getDefaultState();
const getters = {
  user(state) {
    return state.user;
  },
  region_details(state) {
    return state.region_details;
  },
};
const actions = {
  async [LOGIN](context, payload) {
    try {
      let { data } = await ApiService.post("/auth/login", payload);
      context.commit(SET_AUTH, data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
  async [LOGOUT](context, payload) {
    let { data } = await ApiService.post("/auth/logout", payload);
    localStorage.clear();
    context.commit(CLEAR_VUEX, false);
    return data;
  },

  async [REQUEST_RESET_PASSWORD](context, payload) {
    try {
      let { data } = await ApiService.post("/auth/forgot", payload);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
  async [UPDATE_PASSWORD](context, payload) {
    try {
      let { data } = await ApiService.post("/auth/reset", payload);
      context.commit(SET_LOGGEDIN_USER_DETAILS, data);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
};

const mutations = {
  [SET_AUTH](state, response) {
    let roles = _.get(response, "data.user.roles", []);
    let roleID = roles[0].role_id;
    let regionsUser = _.get(response, "data.user", []);
    let regionName = regionsUser.region_name;
    if (response.success == true) {
      state.roleID = roleID;
      state.regionName = regionName;
      localStorage.removeItem("userToken");
      const token = "Bearer" + " " + _.get(response, "data.token", 0);
      localStorage.setItem("userToken", token);
      localStorage.setItem("roleID", roleID);
      localStorage.setItem("regionName", regionName);
      localStorage.setItem(
        "userObj",
        _.get(response, "data.user.roles.role_id", {})
      );
      localStorage.setItem(
        "isPasswordReset",
        _.get(response, "data.user.is_temp", 0)
      );
      ApiService.setHeader();
      state.access_token = token;
      state.user = _.get(response, "data.user", {});
      localStorage.setItem("userLoggedInId", state.user.id);
    }
  },
  [SET_LOGGEDIN_USER_DETAILS](state, response) {
    if (response.success == true) {
      localStorage.setItem("userId", _.get(response, "data.user.id", 0));
      localStorage.setItem("userObj", _.get(response, "data.user", 0));
      localStorage.setItem(
        "isPasswordReset",
        _.get(response, "data.user.is_temp", 0)
      );
      state.logged_in_user_details = response.data.user;
    }
  },
  [CLEAR_VUEX](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_REGION](state, list) {
    state.region_details.region_name = list.region_name
      ? list.region_name
      : list.name;
    state.region_details.region_id = list.region_id ? list.region_id : list.id;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
