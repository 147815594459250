import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import regeneratorRuntime from "regenerator-runtime";
import { iconsSet as icons } from "./assets/icons/icons.js";
import i18n from "@/plugins/i18n";
import store from "./store/index";
import CxltToastr from "cxlt-vue2-toastr";
import "cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vuelidate from "vuelidate";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-datepicker/index.css";
import VueGeolocation from "vue-browser-geolocation";
import * as VueGoogleMaps from "vue2-google-maps";
import VueImg from 'v-img';
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);
Vue.use(Vuelidate);
Vue.use(VueLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(regeneratorRuntime);
Vue.use(VueGeolocation);
const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: true,
  // Event listener to open gallery will be applied to <img> element
  openOn: 'click',
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
}
Vue.use(VueImg, vueImgConfig);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBgIhSyTebW151wbzQGtpOce6p1sx5lC58",
    language: "en",
    libraries: "places",
    callback: "initMap",
  },
});
Vue.use(CxltToastr, {
  position: "top center",
  timeOut: 5000,
  hideDuration: 0,
  useHtml: true,
});
new Vue({
  el: "#app",
  router,
  i18n,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
  }
};
